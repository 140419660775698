body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "RubikBold";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/Rubik-Bold.ttf");
}

@font-face {
  font-family: "RubikBlack";
  font-style: normal;
  font-weight: 900;
  src: url("./assets/Rubik-Black.ttf");
}

@font-face {
  font-family: "RubikExtraBold";
  font-style: normal;
  font-weight: 800;
  src: url("./assets/Rubik-ExtraBold.ttf");
}

@font-face {
  font-family: "RubikLight";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/Rubik-Light.ttf");
}

@font-face {
  font-family: "RubikMedium";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/Rubik-Medium.ttf");
}

@font-face {
  font-family: "RubikRegular";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/Rubik-Regular.ttf");
}

@font-face {
  font-family: "RubikSemiBold";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/Rubik-SemiBold.ttf");
}
